
import Vue from 'vue';

interface Props {
  title: string;
  icon: string;
  info: string;
  stroke: boolean;
  fill: boolean;
}

export default Vue.extend<unknown, unknown, unknown, Props>({
  props: {
    icon: {
      type: String,
      required: true,
    },
    info: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    stroke: {
      type: Boolean,
      default: false,
    },
    fill: {
      type: Boolean,
      default: false,
    },
  },
});
