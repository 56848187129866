
import Vue from 'vue';

interface Props {
  email: string | null;
}

export default Vue.extend<unknown, unknown, unknown, Props>({
  props: {
    email: String,
  },
});
