
import Vue from 'vue';
import { mapGetters } from 'vuex';

import InfoBlock from '@/components/Landing/InfoBlock.vue';
import RecipeButton from '@/components/UI/UserProfile/shared/RecipeButton.vue';
import LoggedInActions from '@/components/Landing/LoggedInActions.vue';
import PianoCustomerStateHandler from '../../libraries/piano/src/components/PianoCustomerStateHandler.vue';

interface Methods {
  openUrl: (url: string) => void;
}

interface Computed {
  hasAccess: boolean;
}

export default Vue.extend<unknown, Methods, Computed, unknown>({
  layout: 'landing',
  name: 'Landing',
  components: {
    PianoCustomerStateHandler,
    InfoBlock,
    RecipeButton,
    LoggedInActions,
  },
  computed: { ...mapGetters(['hasAccess']) },
  methods: {
    openUrl(url: string) {
      window?.open(url, '_blank');
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$nuxt.$loading.start();
      setTimeout(() => this.$nuxt.$loading.finish(), 300);
    });
  },
});
